import React, { useState, useEffect } from 'react';
import data from './data.json';
import { Helmet } from 'react-helmet'; // Add this import
import seedrandom from 'seedrandom';

function Game() {
    const [gameData, setGameData] = useState([]);
    const [unplayedGames, setUnplayedGames] = useState([]);
  const [currentGame, setCurrentGame] = useState(null);
  const [currentGuess, setCurrentGuess] = useState([]);
  const [foundGroups, setFoundGroups] = useState({});
  const [attempts, setAttempts] = useState(4);
  const [message, setMessage] = useState('');
  const [animation, setAnimation] = useState(null);
  const [animationWords, setAnimationWords] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameId, setGameId] = useState(null); // Add this line
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [selectionStreak, setSelectionStreak] = useState([]);

  const levelColors = ['#fbd400', '#b5e352', '#729eeb', '#bc70c4'];
  const levelEmojis = ["🔵", "🟢", "🟡", "🔴", "🟣"]; // Choose your own emojis


  useEffect(() => {
    setTimeElapsed(0);  // Reset the timer when starting a new game
  
    // Other codes
  
    const timer = setInterval(() => {
      setTimeElapsed(timeElapsed => timeElapsed + 1);
    }, 1000);
  
    return () => clearInterval(timer);
  }, [gameId]);  // Only depend on gameId

  function mixGroups(gameId) {
    const rng = seedrandom(gameId); // Create seeded random number generator
    let newGame = { groups: {}, startingGroups: [[], [], [], []] };
    let usedWords = new Set();
  
    for(let i=0; i<4; i++) {
      let randomId, randomGame, groupKey;
  
      // Keep trying until a suitable group is found
      do {
        do {
          randomId = Math.floor(rng() * data.length);
        } while(randomId === gameId); // Ensure that we don't select the same game
  
        randomGame = data[randomId];
  
        do {
          groupKey = Object.keys(randomGame.groups)[Math.floor(rng() * Object.keys(randomGame.groups).length)];
        } while(randomGame.groups[groupKey].level !== i);
      } while(randomGame.groups[groupKey].members.some(member => usedWords.has(member))); // Check if the members are already used
  
      newGame.groups = {...newGame.groups, [groupKey]: randomGame.groups[groupKey]};
          
      // Shuffle members into startingGroups
      randomGame.groups[groupKey].members.forEach((member, index) => {
        usedWords.add(member); // Add the member to the usedWords set
  
        let insertionRow;
        do {
          insertionRow = Math.floor(rng() * 4);
        } while (newGame.startingGroups[insertionRow].length === 4);
        newGame.startingGroups[insertionRow].push(member);
      });
    }
    
    return newGame;
  }
  
  const shuffleArray = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  function chunkArray(array, size) {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  }
  
  
  

  useEffect(() => {
    setGameData(data);
    setTimeElapsed(0);
    setUnplayedGames([...Array(data.length).keys()]);
    let gameIndex;

    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get('gameId');
    setGameId(gameId);
    if(gameId){
        gameIndex = gameId;
        let newGame = mixGroups(gameIndex);
        
        // Shuffle each row in startingGroups
        let flattenedGroups = newGame.startingGroups.flat();
        flattenedGroups = shuffleArray(flattenedGroups);
        newGame.startingGroups = chunkArray(flattenedGroups, 4);

        console.log(newGame)
        setCurrentGame(newGame);
    } else {
        gameIndex = generateIndexConnections() % data.length;
        let game = data[gameIndex];
        
        // Shuffle each row in startingGroups
        game.startingGroups = game.startingGroups.map(group => shuffleArray(group));
        setCurrentGame(game);
    }

    setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== gameIndex));
    // Reset the timer and selection streak when a new game is started
    setSelectionStreak([]);
  }, [gameId]);



  function generateIndexConnections() {
    const D = 864e5;
    const e = new Date("7/6/2023");
    const t = (new Date()).setHours(0, 0, 0, 0) - e.setHours(0, 0, 0, 0);
    let n = Math.round(t / D);
    return n < 0 ? Math.abs(n) : n;
  }

  const handleWordClick = word => {
    // If the word is already selected, remove it from the currentGuess
    if (currentGuess.includes(word)) {
      setCurrentGuess(currentGuess.filter(guess => guess !== word));
    } 
    // Else if there are less than 4 items in the currentGuess, add the word
    else if (currentGuess.length < 4) {
      setCurrentGuess([...currentGuess, word]);
    } 
    // If there are 4 items already and the word isn't one of them, ignore the click
    else {
      return;
    }
  };

  
  

  const submitGuess = () => {
    if (currentGuess.length !== 4) {
        setMessage('Necesitas seleccionar 4 elementos antes de enviar');
        return;
      }
      console.log(currentGame)
      const guessedGroup = Object.entries(currentGame.groups).find(
        ([groupName, group]) =>
          JSON.stringify(group.members.sort()) === JSON.stringify(currentGuess.sort())
      );

    if (guessedGroup) {
      setFoundGroups({
        ...foundGroups,
        [guessedGroup[0]]: guessedGroup[1].members,
      });

      const newStartingGroups = currentGame.startingGroups
        .flat()
        .filter(word => !currentGuess.includes(word));

      setCurrentGame({
        ...currentGame,
        startingGroups: chunkArray(newStartingGroups, 4),
      });
      
      setMessage('¡Felicidades! ¡Has encontrado un grupo!');
        setAnimation('success');
        setAnimationWords(currentGuess);
        setTimeout(() => {
            setCurrentGuess([]);
            setAnimation(null);
            setAnimationWords([]);
        }, 1000);
    } else {
        setAnimation('error');
        setAnimationWords(currentGuess);
        setTimeout(() => {
            setAnimation(null);
            setAnimationWords([]);
        }, 1000);
      setMessage('Respuesta incorrecta, por favor intenta de nuevo.');
        setAttempts(prevAttempts => {
            if(prevAttempts - 1 === 0) {
                setGameOver(true);
                setMessage('¡Juego terminado! ¡Se han agotado todos los intentos!');
            }
            return prevAttempts - 1;
        });
    }

    const wordLevels = currentGuess.map((word) => {
      // Find the group to which the word belongs
      const groupName = Object.keys(currentGame.groups).find(
        key => currentGame.groups[key].members.includes(word)
      );
    
      // Get the level of this group
      const level = currentGame.groups[groupName].level;
    
      // Return the word and its corresponding level
      return { word, level };
    });
  
    setSelectionStreak([...selectionStreak, wordLevels]);
  };

  const startNewGame = () => {
    if (unplayedGames.length === 0) {
      setMessage('¡No quedan más juegos!');
      return;
    }

    const randomIndex = Math.floor(Math.random() * unplayedGames.length);
    const nextGameIndex = unplayedGames[randomIndex];
    setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== nextGameIndex));

    // Redirect to new page with gameId as URL parameter
    window.location.href = window.location.origin + '?gameId=' + nextGameIndex;
  };

  const restartGame = () => {
    window.location.reload();
  };

  const resetGuess = () => {
    setCurrentGuess([]);
  };

  const crearjuego = () => {
    window.location.href = 'https://www.customconnections.org/'
  }

  const copyResultsToClipboard = () => {
    // Create a string from the selection streak
    const resultsString = selectionStreak.map((guess, index) => 
      guess.map((wordObj) => `${levelEmojis[wordObj.level]}`).join('')
    ).join('');
  
    // Copy the string to the clipboard
    navigator.clipboard.writeText('ConnectionsEspanol.com - ' + resultsString);
    // Show an alert message
    alert('Copiado al portapapeles, ahora puedes compartir el resultado en redes sociales.');
  };
  
  

  return (
    <>
      {gameId && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}       
       
        {Object.entries(foundGroups).map(([groupName, words], index) => (
        <div
          className="game-group"
          key={groupName}
          style={{ backgroundColor: levelColors[index % levelColors.length] }}
        >
          <h3 className="group-name">{groupName}</h3>
          <div className="group-members">{words.join(', ')}</div>
        </div>
      ))}

<div class="top-g"><p class="p-text">Crea cuatro grupos de cuatro.</p><div class="game-time">Tiempo: {`${Math.floor(timeElapsed / 60).toString().padStart(2, '0')}:${(timeElapsed % 60).toString().padStart(2, '0')}`}</div></div>
      

      {currentGame && currentGame.startingGroups.map((group, groupIndex) => (
        <div className="game-board" key={groupIndex}>
          {group.map((word, wordIndex) => (
            <button
            className={`game-item 
              ${currentGuess.includes(word) ? 'selected' : ''} 
              ${animationWords.includes(word) ? `${animation}-animation` : ''} 
              ${word.length === 8 ? 'size-8' : ''} 
              ${word.length > 8 ? 'size-more' : ''}`}
            key={wordIndex}
            onClick={() => handleWordClick(word)}
          >
            {word || ' '}
          </button>
          
          ))}
        </div>
      ))}
      {message && <div className="message">{message}</div>}
      {gameOver ? (
        <>
          <button className="game-btn" onClick={restartGame}>Restart the same Game</button>
          <button className="game-btn" onClick={startNewGame}>Start New Game</button>
        </>
      ) : (
        <>
          {currentGame && Object.keys(foundGroups).length === Object.keys(currentGame.groups).length ? (
            <>
                <div className="congratulations">
                    <h2>¡Felicidades!</h2>
                    <p>¡Has encontrado todos los grupos!</p>
                    <div className="share">
  {selectionStreak.map((guess, index) => (
    <div key={index} className="share-row">
      {guess.map((wordObj, i) => (
        <span key={i} style={{ backgroundColor: levelColors[wordObj.level] }}>
          
        </span>
      ))}
    </div>
  ))}
</div>
<button className="game-btn" style={{ backgroundColor:'#000000', color:'#fff', marginTop:'10px' }} onClick={copyResultsToClipboard}>Share Results</button>
                </div>
                <button className="game-btn" onClick={startNewGame}>Nuevo Juego</button>
            </>
        
          ) : (
            <div className="btn-wrapper">
              <button className="game-btn submit-btn" onClick={submitGuess}>Enviar</button>
              <button className="game-btn" onClick={resetGuess}>Deseleccionar</button>
              <button className="game-btn" onClick={crearjuego}>Crear Juego</button>
              { /*<button className="game-btn" onClick={startNewGame}>Nuevo Juego</button> */ }
            </div>
          )}
          <div className="game-attempts">Errores restantes: {attempts}</div>
        </>
      )}

      
    </>
  );
}

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export default Game;